import { Form, Link, useLoaderData, useLocation } from '@remix-run/react';
import { ValidatedForm } from 'remix-validated-form';
import {
  json,
  type ActionFunction,
  type LoaderFunctionArgs,
} from '@remix-run/node';
import { authenticator } from '~/lib/server/auth.server';

import { getSession } from '~/lib/server/session.server';
import { withZod } from '@remix-validated-form/with-zod';
import { z } from 'zod';
import { Button, DefaultErrorBoundary, Input, SubmitButton } from '@cardo/ui';
import { returnToCookie } from '~/lib/server/cookies.server';
import { HoneypotInputs } from 'remix-utils/honeypot/react';
import { checkHoneypot } from '~/lib/server/honeypot.server';
import GoogleIcon from '~/components/icon/GoogleIcon';

export const ErrorBoundary = DefaultErrorBoundary;

export const action: ActionFunction = async ({ request }) => {
  const requestClone = await checkHoneypot(request);

  const returnTo = await returnToCookie.parse(request.headers.get('Cookie'));

  return await authenticator.authenticate('user-pass', requestClone, {
    successRedirect: returnTo ?? '/',
    failureRedirect: '/login',
  });
};

export async function loader({ request }: LoaderFunctionArgs) {
  const url = new URL(request.url);
  const returnTo = url.searchParams.get('returnTo');

  let headers = new Headers();
  if (returnTo) {
    headers.append('Set-Cookie', await returnToCookie.serialize(returnTo));
  }

  await authenticator.isAuthenticated(request, {
    successRedirect: returnTo ?? '/',
  });
  const session = await getSession(request.headers.get('cookie'));
  const error = session.get(authenticator.sessionErrorKey);
  return json({ error }, { headers });
}

export const validator = withZod(
  z.object({
    username: z.string().min(1, {
      message: 'Username is required',
    }),
    password: z.string().min(1, { message: 'Password is required' }),
  })
);

export default function Login() {
  const { error } = useLoaderData<typeof loader>();

  const location = useLocation();
  const errorThrower = new URLSearchParams(location.search).get('error');

  return (
    <div className="flex h-full flex-col items-center sm:pt-24 gap-8">
      <h1 className="text-4xl font-semibold">Login</h1>
      <ValidatedForm
        validator={validator}
        method="post"
        className="flex w-full flex-col space-y-3 sm:w-fit"
        id="login-form"
      >
        <HoneypotInputs label="Please leave this field blank" />
        <div className="flex flex-col">
          <Input
            name="username"
            label="Email/Username:"
            type="text"
            formId="login-form"
            autocomplete="username email"
          />
          <Input
            name="password"
            label="Password:"
            type="password"
            formId="login-form"
            autocomplete="current-password"
          />
        </div>
        <SubmitButton
          label="Log in"
          labelSubmitting="Logging in..."
          formId="login-form"
        />
        {error && errorThrower !== 'google' && (
          <p className="mt-3 text-red-600">{error.message}</p>
        )}
      </ValidatedForm>
      <div className=" flex gap-3 text-xs">
        <Link to="/signup">Sign up</Link>
        <div>|</div>
        <Link to="/forgotpassword">Forgot password?</Link>
      </div>
      <div className="flex gap-2 items-center w-full sm:w-96 ">
        <div className="flex-grow h-px border-b border-gray-400" />
        <div className="text-gray-500 text-sm">or</div>
        <div className="flex-grow h-px border-b border-gray-400" />
      </div>
      <Form
        method="post"
        action="/auth/google"
        className="no-underline w-full sm:w-96 flex flex-col space-y-3"
      >
        <Button
          large
          type="submit"
          className="flex items-center gap-2.5 w-full text-center justify-center"
        >
          <GoogleIcon />
          Connect with Google
        </Button>
        {error && errorThrower === 'google' && (
          <p className="mt-3 text-red-600">{error.message}</p>
        )}
      </Form>
    </div>
  );
}
